// 引入工具函数
import {
  http,
  httpimg
} from '../../api/index'

// 他区协查函件管理-列表
export const lettersPageQuery = params => http.post('/ohealth/api/v1/epidemicControl/epidemiologicalsurvey/lettersPageQuery', params)
// 他区协查函件管理-人员下拉框
export const userSelectedList = params => http.post('/ohealth/api/v1/epidemicControl/epidemiologicalsurvey/userSelectedList', params)


