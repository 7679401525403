<!-- 他区协查函件 by阿旭 -->
<template>
  <div class="main">
    <div class="top">
      <div class="top_sift">
        <div class="top_sift_div">
          发函人: <el-select v-model="regitsterInfo.senderId" placeholder="请选择" clearable>
            <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="top_sift_div">
          发函时间范围: <el-date-picker v-model="ldtime" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd">
          </el-date-picker>
        </div>
        <div class="top_sift_div">
          发函区域:
          <el-cascader class="select-width" ref="taskSourceRef" v-model="regitsterInfo"
            :props="{ ...dzprops, checkStrictly: true }" @change="handleTaskSource" clearable></el-cascader>
        </div>
      </div>
      <div>
        <el-button type="primary" @click="Inquire()">查询</el-button>
      </div>
    </div>
    <div class="content">
      <el-table :is-show-select="true" :data="tableData" table-size="medium" :goBeyondHeight="40"
        @handleSelectionChange="handleSelection" @expand-change="expandchange" :row-key="getRowKeys"
        :expand-row-keys="expands">
        <el-table-column label="操作" width="120" fixed="left">
          <template slot-scope="{ row }">
            <el-link type="primary" @click="addRegister2('addlddj', row)">函件下载</el-link>
          </template>
        </el-table-column>
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-table :data="props.row.persons" style="width: 100%">
              <el-table-column prop="name" label="姓名" width="180">
              </el-table-column>
              <el-table-column prop="sex" label="性别" width="180">
                <template slot-scope="scope">
                  {{ scope.row.sex | sexFilter() }}
                </template>
              </el-table-column>
              <el-table-column prop="cardType" label="证件类型" width="180">
                <template slot-scope="scope">
                  {{ scope.row.cardType | dictFilter2(cardTypeDict) }}
                </template>
              </el-table-column>
              <el-table-column prop="cardNo" label="证件号码" width="180">
              </el-table-column>
              <el-table-column prop="crowdTypeName" label="人群分类" width="180">

              </el-table-column>
              <el-table-column prop="surveyObjectBelongArea" label="所属地区">
              </el-table-column>
              <el-table-column prop="address" label="详细地址">
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column label="函件编号" prop="letterNo" width="200"></el-table-column>
        <el-table-column label="发函人" prop="name" width="180"></el-table-column>
        <el-table-column label="发函时间" prop="sendTime" width="180"></el-table-column>
        <el-table-column label="接收区域" prop="receiveArea" width="220"></el-table-column>
        <el-table-column label="协查人数" prop="personNum"></el-table-column>

      </el-table>

    </div>
    <div class="bttom">
      <el-pagination style="margin-top: 20px" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="currentPage" :page-sizes="[10, 50, 100, 200]" :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { http } from "@/api/index";
import { lettersPageQuery, userSelectedList } from "@/api/Taskmanagement/HemanagementDistrict";
import {
  getIdNumberInfo,
  downloadExportFile,
  importFile,
  importUrlFile,
} from "@/utils/utils";
import { Loading } from "element-ui";
import constants from "@/common/constants";

export default {
  data() {
    return {
      value: '',
      ldtime: [],
      ids: [],
      regitsterInfo: {},
      options: [],
      currentPage: 1,
      total: 0,
      pagesize: 10,
      expands: [],
      tableData: [],
      getRowKeys(row) {
        // 行数据的Key
        return row.id;
      },
      //3级
      dzprops: {
        lazy: true,
        lazyLoad(node, resolve) {
          if (node.level == 0) {
            let cities = JSON.parse(localStorage.getItem("dqlist"));
            setTimeout(() => {
              resolve(cities);
            }, 0);
          } else {
            let formm = { label: node.data.value };
            http({
              method: "post",
              url: `/ohealth/api/v1/system/common/subList`,
              data: formm,
            }).then(function (response) {
              let list = response.data.data;
              const nodes = list.map((item) => ({
                value: item.value,
                label: item.label,
                leaf: item.leaf == 3,
              }));
              resolve(nodes);
            });
          }
        },
      },
    };
  },
  filters: {
    crowdType(val) {
      for (let i in constants.TYPEPOP_SELECT) {
        if (val == constants.TYPEPOP_SELECT[i].value) {
          val = constants.TYPEPOP_SELECT[i].label;
        }
      }
      return val;
    },
  },
  created() {
    this.cardTypeDict = constants.TYPE_CERTIFICATE;

    this.defaultDate()
    this.Inquire()
    this.userSelectedList()
  },
  mounted: {},

  methods: {
    //选择任务来源
    handleTaskSource() {
      let node = this.$refs.taskSourceRef.getCheckedNodes()[0];
      if (node) {
        let taskSource = node.pathLabels.toString();
        this.regitsterInfo.taskSource = taskSource;
      }
    },
    handleSizeChange(val) {
      this.pagesize = val;
      this.Inquire();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.Inquire();
    },
    // 默认时间
    defaultDate() {
      let date = new Date()
      let year = date.getFullYear().toString();
      let month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString();
      let da = date.getDate() < 10 ? '0' + date.getDate().toString() : date.getDate().toString();
      let end = year + '-' + month + '-' + da;
      let beg = year + '-' + month + '-01';
      this.ldtime = [end, end] //将值设置给插件绑定的数据
    },
    // 查询
    async Inquire() {
      console.log("🚀 ~ file: HemanagementDistrict.vue ~ line 195 ~ Inquire ~ this.ldtime", this.ldtime)

      if (this.ldtime && this.ldtime.length != 0) {
        this.regitsterInfo.startTime = this.ldtime[0].toString() + " 00:00:00";
        this.regitsterInfo.endTime = this.ldtime[1].toString() + " 23:59:59";
      } else {
        this.regitsterInfo.startTime = "";
        this.regitsterInfo.endTime = "";
      }
      let params = {
        startTime: this.regitsterInfo.startTime,
        endTime: this.regitsterInfo.endTime,
        pageNumber: this.currentPage,
        pageSize: this.pagesize,
        receiveArea: this.regitsterInfo.taskSource,
        senderId: this.regitsterInfo.senderId
        // senderId	发函人ID		false	
      }
      try {
        let { data } = await lettersPageQuery(params)
        if (data.code == "200") {
          this.tableData = data.data.records;
          this.total = +data.data.total
        }
      } catch (error) {

      }

    },
    //接收表格的选中项
    handleSelection(val, name) {
      this.ids = val;
    },
    expandchange(row, expandedRows) {
      console.log("expandchange -> expandedRows", expandedRows);
      this.ffdata = [];
      this.zheh = row;

      let params = {
        transportTaskId: row.id,
      };
      this.expands = [];
      if (expandedRows.length > 0) {
        row ? this.expands.push(row.id) : "";
      }
      // getEpidemiologicalSurveyListByTaskId({ params }).then(res => {
      //   this.ffdata = res.data.data;
      //   this.expands = [];
      //   if (expandedRows.length > 0) {
      //     row ? this.expands.push(row.id) : "";
      //   }
      // });

      this.$forceUpdate();
    },
    async userSelectedList() {
      let params = {
        peopleType: 4
      }
      try {
        let { data } = await userSelectedList(params)
        this.options = data.data
      } catch (error) {

      }
    },
    //函件下载
    addRegister2(type, row) {
      console.log("addRegister -> row", row);
      //文件下载
      let loadingInstance = Loading.service();
      Loading.service();
      let fileUrl = row.url;

      importUrlFile(fileUrl, '函件');

      setTimeout(() => {
        loadingInstance.close();
      }, 1000);
    },
  }
}

</script>
<style lang='scss' scoped>
.main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  margin-top: 20px;
  flex: 1;
  overflow-x: hidden;
  overflow-y: scroll;
}

.top {
  display: flex;
  justify-content: space-between;
}

.top_sift {
  display: flex;

}

.top_sift_div {
  margin-right: 20px;
}

.bttom {
  display: flex;
  justify-content: flex-end;
}

/deep/ {
  //修改表头的背景颜色横向渐变色
  .el-table {
    thead {
      // color: #fff;
      font-weight: 500;
      // background: linear-gradient(to right, #6fa3fe, #4cdafe) !important;

      & th {
        //inherit：规定应该从父元素继承 background-color 属性的设置。
        //transparent：默认。背景颜色为透明。
        // background-color: inherit !important;
        background-color: transparent;
      }

      & tr {
        // background-color: inherit !important;
        background-color: transparent;
      }
    }
  }
}
</style>